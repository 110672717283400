import React, { useRef, useState, useEffect } from 'react'

import styled from 'styled-components'

const MasonryDiv = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${props => props.gap || `1em`};
`

const Col = styled.div`
  display: grid;
  grid-gap: ${props => props.gap || `1em`};
  grid-auto-rows: max-content;
`

export default function Masonry ({ children, gap, minWidth = 300 }) {
  const cols = []
  const ref = useRef()
  const [numCols, setNumCols] = useState(3)

  const calcNumCols = () => {
    const newCols = Math.max(1, Math.floor(ref.current.offsetWidth / minWidth))
    setNumCols(newCols)
  }

  const createCols = () => {
    for (let i = 0; i < numCols; i++) cols[i] = []
    children.forEach((child, i) => cols[i % numCols].push(child))
  }

  useEffect(() => {
    calcNumCols()
    window.addEventListener(`resize`, calcNumCols)
    return () => window.removeEventListener(`resize`, calcNumCols)
  })
  createCols()

  return (
    <MasonryDiv ref={ref} gap={gap}>
      {Array(numCols)
        .fill()
        .map((el, i) => (
          <Col key={i} gap={gap}>
            {cols[i]}
          </Col>
        ))}
    </MasonryDiv>
  )
}
