import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout/rootLayout"
import InspirationRoot from "@components/inspiration/inspirationRoot"
import Helmet from "react-helmet"
import SEO from "@components/seo"

const InpsirationPage = (props) => {
  const {
    pageContext: { locale },
  } = props
  return (
    <Layout locale={locale}>
      <SEO
        title="Photography inspiration gallery"
        banner="/res/inspiration.jpg"
        pageLanguage={locale}
        desc="Hand-picked inspirational galleries for photography enthusiasts"
      />
      {locale == "ru" && (
        <SEO
          title={`Галерея вдохновляющих фотографий`}
          banner="/res/inspiration.jpg"
          pageLanguage={locale}
          desc={`Собранная вручную галерея вдохновляющих фотографий`}
        />
      )}

      <InspirationRoot {...props} />
    </Layout>
  )
}

export default InpsirationPage

export const query = graphql`
  query InspirationListQuery {
    allDirectory(filter: { absolutePath: { regex: "/^.*(_inspirator.+)$/" } }) {
      edges {
        node {
          relativePath
          relativeDirectory
        }
      }
    }
    allFile(
      filter: { absolutePath: { regex: "/^.*(_inspirator.+)cover.jpg$/" } }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            gatsbyImageData(width: 786, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
