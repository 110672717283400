import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { core } from '@src/style'
import device from '@src/style/device'
import Linker from '@src/common/linker'
import { LocalizedLink, localizedUrl } from '../link'
import { injectIntl } from 'react-intl'

const H3 = styled(core.H3)`
  color: ${props => props.theme.blog.textColor};
`
const P = styled(core.PXS)`
  color: ${props => props.theme.blog.textColor};
`

const Post = styled.div`
  position: relative;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: 1rem;
  min-width: 280px;
  max-width: 500px;
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`

const Image = styled.div`
  .gatsby-image-wrapper {
    img {
      transition: transform 0.2s !important;
    }
  }
  ${Post}:hover & img {
    transform: scale(1.05);
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  background: rgb(15, 15, 15);
  background: linear-gradient(
    0deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  width: 100%;
  height: 100%;

  transition: opacity 0.1s linear;

  &:hover {
    opacity: 0.8;
  }
`

const Text = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: white;
  ${props => props.theme.typography.headline.S}

  a {
    color: white;
    &:hover {
      color: white;
    }
  }
`

export default injectIntl(({ intl, item }) => {
  const link = localizedUrl(item.link, intl.locale)
  return (
    <Post
      onClick={() => {
        Linker.replaceSlug(link)
      }}
    >
      <Fragment>
        <Image>
          <GatsbyImage image={item.cover.gatsbyImageData} alt="Cover image" />
        </Image>
        <Overlay>
          <Text>
            <Link to={link}>{item.name}</Link>
          </Text>
        </Overlay>
      </Fragment>
    </Post>
  );
})
