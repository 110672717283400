import React from 'react'
import {
  InspirationRoot,
  InspirationRootMessageWrapper,
  InspirationRootMessage
} from './styles'
import Nav from '@components/nav/nav'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'
import Masonry from '@components/blog/masonry'
import { allDirectoryEdgesToMenu } from '@src/components/common/menuHelper'
import InspirationCard from './inspirationCard'

export default props => {
  const { data } = props

  const intlExtras = {}
  const menu = allDirectoryEdgesToMenu(
    data.allDirectory.edges,
    'inspiration'
  ).map((item, index) => {
    const cover = data.allFile.edges.filter(n => item.link.includes(n.node.relativeDirectory))[0].node.childImageSharp.gatsbyImageData
    return {
      ...item,
      cover: {
        gatsbyImageData: cover
      }
    }
  })

  return (
    <InspirationRoot>
      <Nav defaultIndex={2} rootUrl='inspiration' />
      <InspirationRootMessageWrapper>
        <InspirationRootMessage>
          <StyledMessages id='inspiration.root' values={intlExtras} />
        </InspirationRootMessage>
        <Masonry>
          {menu.map((item, index) => {
            return <InspirationCard key={index} item={item} />
          })}
        </Masonry>
      </InspirationRootMessageWrapper>
    </InspirationRoot>
  )
}
